/* scrollbar */
.notesPopUp::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.notesPopUp::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.notesPopUp::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.notesPopUp::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.notesPopUp {
 
  max-height: 60vh;
  overflow-y: auto; 
  scrollbar-width: thin;
}