.customSubTbl.table tbody tr:hover {
  background-color: #EEEEEE !important;
  color: black;
}

.customSubTbl.table tr:first-child td,
.customSubTbl.table tr:first-child th {
  font-size: 12px;
}

.card {
  box-shadow: none !important;
  margin-bottom: 0px;
  border-radius: 0px !important;
}

.btnUnselectedFilter {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
  color: #636a60 !important;
}

.survey_details_badge {
  line-height: 6px !important;
}

.badge-super {
  position: inherit !important;
  line-height: 6px !important;
}

.surveyDetailssearchClear {
  margin-top: 10px;
  margin-left: 10px;
}

@media (max-width: 600px) {
  .surveyDetailssearchClear {
    margin-top: 10px;
    margin-left: 5px;
  }
}