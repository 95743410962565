.site-emission-overview-map{
    height: 400px;
    width: 86%;
    overflow: hidden;
}
.site-emission-by-source{
    height: 400px;
    margin-left: -88px;
    overflow: hidden;
}
.site-emission-by-size{
    height: 360px;
    width: 168%;
    overflow: hidden;

}


.emission-by-size-chart{
    padding: 24px;
    text-align: center;
    margin-top: 40px;
    color: rgba(0,0,0,0.87);
}

#sitedetailssearch {
    margin-top: 10px;
    width: 25%;
}

@media (max-width: 600px) {
   
    #sitedetailssearch {
        width: 51% !important;
    }

 
    .site-details-customer-name{
        margin-bottom: 90px;
        float: left !important;
    }

    .site-emission-overview-map{
        width: 100%;
        overflow: scroll;
    }

    .site-emission-by-source{
        width: 100%;
        margin-left: 0px;
        overflow: scroll;
    }

    .site-emission-by-size{
        width: 100%;
        overflow: scroll;
    
    }

}

@media (max-width: 920px) {

    .site-emission-overview-map{
        width: 100%;
        overflow: scroll;
    }

    .site-emission-by-source{
        width: 100%;
        margin-left: 0px;
        overflow: scroll;
    }

    .site-emission-by-size{
        overflow: scroll;
    
    }

  
  }