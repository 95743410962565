/* New updated CSS */
body {
    padding: 0 !important;
    margin-bottom: 0 !important;
}

.footer_background_color {
    background-color: rgb(232, 14, 14);
    color: rgb(255, 255, 255);
}

.footer_text {
    text-align: center;
}

#footer {
    z-index: 99;
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;
    padding: 1rem;
    height: 35px;
    padding-top: 10px;
}

.brand-wrap-header {
    align-items: center;
    display: flex;
    position: relative
}

.header__inner {
    align-items: center;
    display: flex;
    height: 39px;
    padding-inline: 0px;
}

.header__brand {
    align-items: center;
    display: flex;
    height: 100%;
    background-color: #e80e0e !important
}

.header__brand.prod {
    background-color: #25476a !important
}

.brand-title {
    /* display: none; */
    padding-right: 14px;
    font-size: 14px !important;
    
}

.header__content-start {
    flex-grow: 0
}

.brand-title {
    color: #fff !important;
    display: block;
    font-family: Ubuntu, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 0rem;
    text-decoration: none !important
}

.hd--expanded.mn--max .content__header:before,
.hd--expanded.mn--min .content__header:before {
    background-color: unset !important;
    content: "";
    display: block;
    height: .65625rem;
    margin-left: 0;
    -webkit-mask-image: radial-gradient(circle at 0 .65625rem, transparent 0, transparent .65625rem, #000 .65625rem);
    mask-image: radial-gradient(circle .65625rem at 0 .65625rem, transparent 0, transparent .65625rem, #000 .65625rem);
    position: relative;
    top: -.06125rem;
    transform: translateX(-.65625rem);
    width: .7175rem;
    z-index: 10
}


.root:not(.mn--min) .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content {
    border-inline-start: none;
    margin-inline-start: calc(.25em + 1rem);
    padding-inline-start: .5rem
}

.root:not(.mn--min) .mainnav__menu>.nav-item.has-sub>.mininav-content {
    border-inline-start: none;
    margin-inline-start: 1.75em
}

@media (min-width:992px) {
    .mn--max .mainnav {
        position: relative
    }

    .mn--max .mainnav__inner {
        position: absolute
    }

    .mn--max .mainnav {
        transition: max-width .35s ease;
        will-change: max-width
    }

    .mn--max .header__inner {
        padding-inline-start: 0
    }

    .mn--max .header__content, .mn--min .header__content {
        padding-inline-start: unset;
    }
  
    .mn--max .header__brand {
        padding-inline-start: unset;
        max-width: 13.75rem;
        width: 13.75rem;
        will-change: max-width
    }
}

@media (min-width:1200px) {
    .mn--max .header__brand {
        padding-inline-start: unset;
        background-color: #e80e0e;
    }
    
}

.logo {
    /* background-color: white; */
    height: 33px;
    width: 100px;
}

.panel_div {
    height: 35px;
    /* margin-bottom: 10px; */
    border-bottom: 1px solid black;
}

.panel_icon_div {
    float: right;
    margin: 8px;
}

.panel_icon_div i {
    color: #25476a;
    font-weight: bolder;
    cursor: pointer;
}

.common_panel_heading_test {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #000;
    margin-left: 9px;
}


.content .page-title {
    font-size: 16px;
}

.right-component-to-full-size {
    position: fixed;
    z-index: 10001;
    inset: 0px;
    height: 100%;
    background-color: white;
}

.right-component-to-toggle {
    transition: 0.5s;
    display: none;
}

.custom-fa-icon-style {
    font-weight: 400 !important;
    font-size: 15px !important;
}

.div-loading {
    position: fixed;
    z-index: 15000;
    height: 100%;
    width: 100%;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5) !important;
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.div-loading-inner-div {
    margin-left: 45%;
    margin-top: 25%;
    height: 18%;
    z-index: 1 !important;
}

.div-loading-text {
    color: black !important;
    justify-self: center;
    font-size: 19px;
    text-align: center;
    margin-top: 9px;
    height: 136px;
    font-family: 'Ubuntu';
    position: relative;
}

.div-loading-inner {
    width: 115px;
    height: 115px;
    background-color: transparent;
    position: absolute;
    /* display: none; */
    margin-left: -50px;
    margin-top: -50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    /* optional, center the image */
}

.div-loading-mid {	
    width: 145px;	
    height: 122px;	
    background-color: transparent;	
    position: absolute;	
    /* display: none; */	
    /* margin-left: -50px;	
    margin-top: -50px; */	
    background-size: cover;	
    background-repeat: no-repeat;	
    background-position: center center;	
    /* optional, center the image */	
    /* -webkit-animation: spinner 4000ms infinite linear;	
    -moz-animation: spinner 4000ms infinite linear;	
    -ms-animation: spinner 4000ms infinite linear;	
    -o-animation: spinner 4000ms infinite linear;	
    animation: spinner 4000ms infinite linear; */	
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.top-middle-header-logo {
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(30%, rgba(255, 255, 255, 1)), color-stop(50%, rgba(255, 255, 255, 1)), color-stop(70%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0)));
    padding: 0px 20px;
}

.header-right-fa-icon {
    color: #fff;
}


.downloadTemplateBtn {
    background-color: #03a9f4;
    border-radius: 3px;
    font-weight: 600;
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    margin-left: 1%;
    margin-right: 1%;
}

.SiteUploadBtnSpan {
    margin-left: 1%;
    width: 30%;
    height: 40px;
}

.SiteUploadBtnSpan input[type="file"] {
    visibility: hidden;
}


.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-input__label {
    min-width: 150px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #25476a;
}


/* AOGI : Dashboard */

.aogi_dashboard_survey_overview {
    position: absolute;
    width: 15%;
    margin-left: 17%;
    background-color: #f44336;
    color: #fff;
}

.aogi_dashboard_survey_overview h5 {
    color: #fff;
}

.aogi_dashboard_survey_overview div table {
    width: 100%;
}


.aogi_dashboard_emission_overview {
    width: 20%;
    background-color: #03a9f4;
    color: #fff;
}

.aogi_dashboard_emission_overview h5 {

    color: #fff;
}

.aogi_dashboard_emission_overview div table {
    width: 100%;
}

.aogi_survey_details_upper_div {
    display: grid;
    grid-template-columns: 24% 24% auto;
    grid-template-rows: auto auto auto auto auto auto;
    grid-gap: 10px;
}


@media (max-width: 600px) {	
    .aogi_survey_details_upper_div {	
        display: block;	
        grid-template-columns: 24% 24% auto;	
        grid-template-rows: auto auto auto auto auto auto;	
        grid-gap: 10px;	
    }	
  
      .div-loading-mid{
        padding: 15px !important;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
       -ms-transform: translateX(-50%) translateY(-50%) !important;
       -webkit-transform: translate(-50%, -50%) !important;
       transform: translate(-50%, -50%) !important;
      }

      .div-loading-text {
        margin-top: 240px;
      }


      
}


.aogi_survey_details_survey_overview {
    /* one */
    background-color: #03a9f4;
    border-color: #03a9f4;
    color: #fff;
    border-radius: 0px !important;
    margin-bottom: 2em !important;
}

.aogi_survey_details_survey_overview h5 {
    color: #fff;
}

.aogi_survey_details_survey_overview table {
    width: 100%;
}

.aogi_survey_details_emission_overview {
    /* two */

    background-color: #f44336;
    border-color: #f44336;
    color: #fff;
    border-radius: 0px !important;
    margin-bottom: 2em !important;
}

.aogi_survey_details_emission_overview h5 {

    color: #fff;
}

.aogi_survey_details_emission_overview table {

    width: 100%;
}

.aogi_survey_details_survey_emission {
    /* three */
    grid-column: 3/4;
    grid-row: 1/6;
}

.aogi_survey_details_survey_completion_status {
    /* four */
    margin-top: -25px;
    grid-column: 1/2;
    grid-row: 2/6;
}

.aogi_survey_details_emission_by_size {
    /* five */
    grid-column: 2/3;
    grid-row: 2/6;
    margin-top: -25px;
}

.expand-table {
    width: 80%;
    border: 1px solid black;
    margin: 0px 70px;
}

.expand-table td {
    border: 1px solid black;
}

.expand-table th {
    border: 1px solid black;
}

.top20label {
    font-size: 10px;
    vertical-align: 40%;
    font-weight: 700;
    background-color: #f44336;
    border-radius: 3px;

    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    margin-left: 10px;
}

.active-link-custom{
    color: #25476a !important;
    font-weight: 700;
}

.custom_common_label {
    font-size: 13px;
    font-weight: 700;
}

.searchLabel {
    align-items: center !important;
}

.top-middle-header-logo{
    margin: 0 auto;
}

#sticky {
    position: sticky;
    position: fixed;
    top: 0;
    right: -70px;
    z-index: 10000;
    width: 540px;
    padding: 15px 0;
    font: 19px/2 'Source Code Pro', monospace;
    letter-spacing: 0px;
    color: #f9f9f9;
    background-color: rgba(240, 5, 5, 0.76);
    box-sizing: border-box;
    -webkit-transform: rotate(35deg);
}