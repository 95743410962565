.card_survey_detail_heading {
    margin: 0px;
    margin-bottom: 10px;
}

.survey_details_card_table tr td {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
}

.survey_details_card_body {
    padding: 8px 0px 0px 15px;
}

.bold-values-table {
    font-weight: 700 !important;
    float: right;
}

.top20_label {
    background-color: #f44336 !important;
    font-size: 5px !important;
    vertical-align: 40% !important;
    height: 10px !important;
    width: 10px !important;
    margin-bottom: 10px !important;
}

.card-body {
    padding: 0.25rem 1.25rem !important;
}

.render_time_survey_details {
    float: right;
    color: #FFF;
    font-size: 13px;
    font-weight: normal;
}

.badge_survey_details {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px !important;
  }
  
@media (max-width: 600px) {
    .mobile_status {
        width: 50px !important;
    }

    .mobile_size {
        /* margin-top: 50px!important; */
        margin-left: 15px !important;
        width: 50px !important;
    }

    .mobile_source {
        /* margin-top: 100px!important; */
        margin-left: 22px !important;
        width: 50px !important;
    }
}