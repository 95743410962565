.survey_details_table_div_main {
    display: grid;
    grid-template-columns: 21% 23% auto;

}

.survey_details_filter_button {
    margin-bottom: 20px;

    button {
        // font-size: 9px !important;
        font-weight: 500;
        margin: 1px;
    }
}
