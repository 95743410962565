.progress{
    width: 400%;
    height: 30px;
    border-radius: 0%;
    }
    .progress-bar{
        width: auto;
    }
    .dialog-demo .p-button {
        margin: 0 .5rem 0 0;
        min-width: 10rem;
    }
    
    .dialog-demo p {
        margin: 0;
        line-height: 1.5;
    }
    
    .dialog-demo .p-dialog .p-button {
        min-width: 10rem;
    }
    .searchClear{
        margin-top: 10px;
        margin-left: 10px;
    }
    .btn-create{
        color: white !important;
        background-color: rgb(13, 13, 81)!important ;
        float: right;
        height: 30px;
    }
    .btn-name{
        height: 40px;
        width: 600px;
    }
   
     .cust-detail.p-dropdown .p-dropdown-trigger {
        color: black !important;
        width: 3rem !important;
        width: 10px !important;
       margin-right: 43px!important;
    } 
    .cust-detail{
        height: 40px;
        width: 398px;
    }

    .p-button-text{
        margin-right: -10px !important;
    }
    
    .paginator-demo .image-gallery {
        text-align: center;
        padding: 1rem;
    }
    
    .toast-demo button {
        min-width: 10rem;
        margin-right: .5rem;
    }
  
    .btn-initiate{
        width: 86px !important;
        height: 38px !important;
        background-color: navy !important;
    }
 
    .date-table{
        height: 40px !important;
        width: 200px !important;
    }
    .modalInput{
        width: 100% !important;
    }

    .create-modal-popup{
        width: 50vw !important;
    }
    @media screen and (max-width: 960px) {
        .toast-demo button {
            width: 100%;
            margin-bottom: .5rem;
        }
    }
    @media (max-width: 600px) {
        .mobile_button{
            margin-right: -10px !important;
        }
        #sitebulkuploadsearch {
            width: 51% !important;
        }
        .create-modal-popup{
            width: 90vw !important;
        }
        .mobile_action {
           display: none;
        }
    
    }