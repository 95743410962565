.dashboard_aogi_content_box_first {
  margin-top: calc(-5.5rem - 2rem) !important;
}

.dashboardTblsearchClear {
  margin-top: 10px;
  margin-left: 10px;
}

.dashboard_main_grid_div {
  display: grid;
  grid-template-columns: 60% auto;
  gap: 15px;
}

#sitewithemissionsearch {
  margin-top: 10px;
  width: 55%;
  max-width: 280px;
  min-width: 180px;
}

@media (max-width: 600px) {
  .dashboard_main_grid_div {
    display: block;
    grid-template-columns: 60% auto;
    gap: 15px;
  }

  .dashboardDropdown {
    width: 90% !important;
    margin-left: 30px !important;
  }

  .dashboard_multiselect {
    width: 90% !important;
    margin-left: 30px !important;
  }

  .dashboard-title {
    margin-right: 100px !important;
    margin-bottom: 1px !important;
    width: 100px !important;
  }


  .dashboard-dateTitle {
    margin-left: 0px !important;
    margin-bottom: 1px !important;
  }

  .emission-overview-map {
    overflow-y: scroll !important;
  }

  .all-surveys-table {
    overflow-y: scroll !important;
  }

  .emission-by-source {
    overflow-y: scroll !important;
  }

  .dashboardTblsearchClear {
    margin-top: 10px;
    margin-left: 5px;
  }

}

@media (max-width: 920px) {
  .emission-overview-map {
    overflow-y: scroll !important;
  }

  .all-surveys-table {
    overflow-y: scroll !important;
  }

  .emission-by-source {
    overflow-y: scroll !important;
  }

  .dashboard_multiselect.p-multiselect {
    width: 100px;
  }

  .dashboardTblsearchClear {
    margin-top: 10px;
    margin-left: 5px;
  }
}

.all-surveys-table {
  grid-column: 1/3;
  overflow: hidden !important;
}

.emission-overview-map {
  height: 400px;
  overflow: hidden;
}

.emission-by-source {
  height: 400px;
  overflow: hidden;
}

.sites-with-conitnues-emission {
  height: 400px;
  overflow: hidden;
  /* overflow-y: auto !important; */
}

/* multi-select css */
.dashboard_multiselect.p-multiselect {
  background: none !important;
  border: none !important;
  color: white !important;
  box-shadow: none !important;

}

.dashboard_multiselect.p-multiselect .p-multiselect-label {
  color: #b1b9bc !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


}

.dashboard_multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: #b1b9bc !important;
}

.dashboard_multiselect.p-checkbox .p-checkbox-box.p-highlight {
  background: #25476a !important;
  border-color: #25476a !important;

}

.dashboard_multiselect.p-multiselect .p-multiselect-trigger {
  color: #b1b9bc !important;
}


/* dropdown cs */

.dashboardDropdown.p-dropdown .p-dropdown-label {
  color: #b1b9bc !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


}

.dashboardDropdown.p-dropdown {
  background: transparent;
  border: none;
}

.dashboardDropdown .p-dropdown-trigger {
  color: #b1b9bc !important;
}

.dashboard-title {
  color: white;
  font-weight: 500;
  font-size: 13px;
  margin-right: 100px;
  width: 100px;
  margin-bottom: 9px;
}

.dashboard-dateTitle {
  color: white;
  font-weight: 500;
  font-size: 13px;
  margin-right: 100px;
  width: 100px;
  margin-bottom: 9px;
  margin-left: 60px;
}

.badge {
  line-height: 14px !important;
  border-radius: 4px !important;
}

/* color: #b1b9bc !important;
} */

.dashboardDropdown.p-dropdown {
  background: transparent;
  border: none;
  width: 100%;
  box-shadow: none !important;
}

.dashboardDropdown .p-dropdown-trigger {
  color: #b1b9bc !important;
}

.badge {
  line-height: 14px !important;
  border-radius: 4px !important;
}

.rdt_Pagination {
  font-size: 11px !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #b1b9bc !important;
}