.lead {
    color: white;
    font-size: 1.5em;
}

.basic-single {
    border: none;
}

.select__menu {
    z-index: 2;
}

.download-button-template {
    background-color: #03a9f4;
    border-radius: 4px;
    display: inline;
    padding: 4px;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}

.download-button-template:hover {
    box-shadow: gray 1px;
}

.create-sites {
    padding: 6px 12px;
    font-size: 13px;
    float: right;
    border-radius: 0px;
    margin-top: 6px;
}

#sitebulkuploadsearch {
    margin-top: 10px;
    width: 25%;
}

#survery-details-page-search{
    margin-top: 10px;
    width: 50%;
}

.customer-select__control {
    min-height: 24px;
    border: none !important;
    background: none;
    background-color: initial !important;
}

.customer-select__control--menu-is-open{
    border-color: var(--text-color) !important;
}

.customer-select__control--is-focused{
    border-color: initial !important;
    box-shadow: none !important;
}


.customer-select__indicator-separator {
    display: none;
}

.customer-select__input-container {
    color: white;
}

.customer-select__single-value {
    color: #6c757d !important;
}

.customer-select__option--is-focused,
.customer-select__option--is-selected {
    background-color: var(--bs-primary) !important;
}

.customer-select__input-container{
    color: white !important;
}

.customer-select__indicator.customer-select__clear-indicator {
    display: none;
}

.required-star {
    color: red;
}

.footer-button{
    padding-right: 0rem !important;
}
.siteDropdown{
    width: 330px !important;
    margin-left: 30px !important;
  }
  .siteDropdown.p-dropdown .p-dropdown-label {
    background: transparent;
    color: #b1b9bc !important;
    width: 170px;
    border: 0 none;
    font-size: 13px !important;
    margin-top: -8px;
    height: 33px !important;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  
  
  }
  
  
  
  .siteDropdown.p-dropdown {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    background: transparent;
    border: none;
    width: 293px !important;
    box-shadow: none !important;
  
  
  
  }
  
  .siteDropdown.p-dropdown .p-dropdown-label.p-placeholder  {
    color: #b1b9bc !important;
    font-size: 12px !important;
    border:  none !important;
  }
  
  .siteDropdown .p-dropdown-trigger {
    color: #b1b9bc !important;
  
    border:  none !important;
    margin-top: -9px;
    margin-right: -6px;
  
  }
  
  
  