.surveyDropdown{
  width: 330px !important;
  margin-left: 30px !important;
}

.surveypage-heading{
  margin-top: -14px;
}



.page-header {
  margin-left: 5px;
  font-size: 19px;
  margin-bottom: 10px;
  margin-top: 8px;
  font-weight: normal;
}

.survey_multiselect.p-multiselect 
{
  background: none !important;
  border: none !important;
  color: white !important;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

}

.survey_multiselect.p-multiselect .p-multiselect-label {
  font-size: 13px !important;
  color: #b1b9bc !important;
  margin-top: -11px;
  margin-left: 20px;

}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {

  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

}


.survey_multiselect.p-checkbox .p-checkbox-box.p-highlight {
  background: #25476a !important;
  border-color: #25476a !important ;

}

.survey_multiselect.p-multiselect .p-multiselect-label.p-placeholder  {
  color:#b1b9bc !important;
  font-size: 13px !important;
  margin-top: -11px;
  margin-left: 20px !important;

}

.survey_multiselect.p-multiselect .p-multiselect-trigger {
  color: #b1b9bc !important;
  margin-top: -11px;


}


.survey_multiselect.p-multiselect-label  {
  color: white !important;
  margin-left: 20px !important;
}


.title {
  color: white;
  font-weight: 500;
  font-size: 13px !important;
  margin-right: 100px;
  width: 100px;
  margin-bottom: 9px !important;
}

.title1 {
  color: white;
  font-weight: 500;
  font-size: 13px !important;
  margin-right: 100px;
  margin-top: 0px;
  width: 100px;
}

.title2{
  color: white;
  font-weight: 500;
  margin-right: 50px;
  margin-top: -100px;
  border:  none !important;
}



.survey_multiselect {
  color: white;
  font-weight: 500;
  margin-right: 30px;
  margin-bottom: -5px !important;
  border:  none !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  box-shadow: none !important;


}

.content_boxed{
  margin-top: 100px !important;

}

.select1 {
    width: 100%;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
  }
  
  .breadcrumb-item1 + .breadcrumb-item1 {
    padding-left: 0.5rem;
  }

  .breadcrumb-item1 > a {
    font-weight: 600;
    text-decoration: none;
  }
  
  .breadcrumb-item1 > a:hover {
    text-decoration: underline;
  }


  .survey_calender.p-calendar .p-inputtext {
    flex: 1 1 auto;
    width: 1%;
    background: transparent !important;
    border: none !important;
    margin-top: 14px;
    font-size: 13px !important;
    box-shadow: none !important;
  } 

.survey_calender.p-calendar.p-calendar-w-btn .p-inputtext {
  order: 2;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


}
.survey_calender.p-calendar.p-calendar-w-btn .p-datepicker-trigger {
  order: 1;
  width: 35px;
  margin-top: 14px !important;
  margin-left: 15px !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

}

.survey_calender .p-button.p-button-icon-only {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

}
.select_index {
  background: transparent !important;
}

.surveyDropdown.p-dropdown .p-dropdown-label {
  background: transparent;
  color: #b1b9bc !important;
  width: 170px;
  border: 0 none;
  font-size: 13px !important;
  margin-top: -8px;
  height: 33px !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


}



.surveyDropdown.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  background: transparent;
  border: none;
  width: 293px !important;
  box-shadow: none !important;



}

.surveyDropdown.p-dropdown .p-dropdown-label.p-placeholder  {
  color: #b1b9bc !important;
  font-size: 12px !important;
  border:  none !important;
}

.surveyDropdown .p-dropdown-trigger {
  color: #b1b9bc !important;

  border:  none !important;
  margin-top: -10px;
  margin-right: -6px;

}

.surveyDropdown.p-dropdown-hover {
  border: none !important;
  border:  none !important;



}

.survey_dropdown.p-dropdown-items .p-dropdown-item {
  color: #99a3a7 !important;

  border:  none !important;


}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: white !important;
  background: #25476a !important;

  border:  none !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


}
::placeholder{
  color: #b1b9bc !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
 }

 @media (max-width: 600px){
   
  .survey_multiselect{
    width: 225px !important;
    margin-left: 10px !important;
  }

  .title {
    margin-right: 100px !important;
    margin-bottom: 3px !important;
    width: 100px !important;
  }

  .title1{
    margin-bottom: 6px !important;
  }

  .survey_calender {
    margin-left: 10px !important;
  }

 }

 @media (max-width: 920px){
   
  .surveyDropdown.p-dropdown {
    
    width: 200px !important;
  }

  
 }