body {
  /* background: #ebedf2; */
  /* font-family: "Poppins", sans-serif; */
}

.empty-state {
  width: 750px;
  margin: 40px auto;
  /* background: #ffffff; */
  /* box-shadow: 1px 2px 10px #e1e3ec; */
  border-radius: 4px;

  &__content {
    padding: 48px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .empty-state__icon {
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      border-radius: 200px;
      justify-content: center;
      /* background-color: #f7fafc; */
      box-shadow: 0px 2px 1px #e1e3ec;

      img {
        width: 170px;
      }
    }

    .empty-state__message {
      color: #38a169;
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 0.85rem;
    }

    .empty-state__help {
      color: #a2a5b9;
      font-weight: 500;
      font-size: 30px;
    }
  }
}

.credit {
  color: #A2A5B9;
  font-size: .75rem;
  text-align: center;

  a {
    color: #444;
  }
}