.videoCameraIcon {
  font-size: 15px;
  cursor: pointer;
  color: #25476a;
}

.videoCameraIconDownload {
  font-size: 15px;
  margin-right: 1%;
  margin-left: 4%;
  cursor: pointer;
}